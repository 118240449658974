import React from 'react';
// import { Line } from '@ant-design/charts';

const Home:React.FC = ():JSX.Element => {
  // const data = [
  //   {month: '2', value: 200},
  //   {month: '3', value: 300},
  //   {month: '4', value: 100},
  //   {month: '5', value: 200},
  //   {month: '6', value: 400},
  //   {month: '7', value: 300},
  //   {month: '8', value: 200},
  //   {month: '9', value: 500},
  //   {month: '9', value: 600},
  // ];
  // const config = {
  //   data,
  //   width: 600,
  //   height: 300,
  //   xField: 'month',
  //   yField: 'value',
  //   point: {
  //     size: 5,
  //     shape: 'diamond',
  //   },
  // };  
  return(
    <>
      <h1>Dashboard</h1>
      {/* <Line {...config} /> */}
    </>
  )
};

export default Home;