import React, { useState } from 'react';
import { Modal, Button, notification, Spin } from 'antd';
import { useMutation } from '@apollo/client';
import { ADD_CATEGORY } from '../../query/mutation';
import SchemaForm from 'antd-schema-form';
import jsonSchema from '../../schema/category.json';

const CategoryAdd = (props: any):JSX.Element => {
  const [addCategory, {loading, error}] = useMutation(ADD_CATEGORY); // eslint-disable-line
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleok = () => {
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const openNotification = (placement: any) => {
    notification.info({
      message: `確認`,
      description:
        'カテゴリを追加しました。',
      placement,
    });
  };

  const postData = async (values: any) => {
    values.recommendFlg = values.recommendFlg ? true : false;
    addCategory({ variables: {title: values.title, recommendFlg: values.recommendFlg}});
    openNotification('topRight');
    handleCancel();
    setLoading(false);
  };

  function onOk(form: any, value: any, keys: Array<string>) {
    setLoading(true);
    const req = postData(value);
    setTimeout(() => {
      console.log(req);
      props.setValue(true);
      console.log(props);
    }, 1000);
  };
  return (
    <>
        <Button type="primary" style={{ marginBottom: 16}} onClick={showModal}>
          カテゴリ追加
        </Button>
        <Modal
          title="確認"
          visible={isModalVisible}
          onOk={handleok}
          onCancel={handleCancel}
          footer={[
            <Button key="close" type="primary" onClick={handleCancel}>閉じる</Button>
          ]}
        >
          <Spin spinning={isLoading} tip="Loading...">
            <SchemaForm
              json={jsonSchema}
              onOk={onOk} 
              formOptions={{ initialValues: {recommendFlg: 0}}}
            />
          </Spin>
        </Modal>
    </>
  );
};
export default CategoryAdd;