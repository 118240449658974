import React from 'react';
import QueueAnim from "rc-queue-anim";
import { ApolloProvider } from '@apollo/client';
import client from '../../graphql/client';
import List from './List';

const Index = ():JSX.Element => {
  return (
    <>
      <QueueAnim>
        <div key="1">
        <ApolloProvider client={client}>
          <List/>
        </ApolloProvider>
        </div>
      </QueueAnim>
    </>
  )
};
export default Index;