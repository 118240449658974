import useSWR from 'swr';
import { request } from 'graphql-request';
import { User } from '../models/user';

interface UserInterface {
  user: User[];
  isLoading: boolean;
  error: any;
}

export const useUser: () => UserInterface = () => {
  const fetcher = (query: any) => request(`${process.env.REACT_APP_API}/graphql`, query);
  const { data, error} = useSWR(
    `{
      users {
        id,
        userId,
        name,
        profile,
        description,
        address,
        hobby,
        gender
      }
    }`,
    fetcher
  );
  return {
    user: data ? data.user : [],
    isLoading: !data && !error,
    error: error,
  }
}