const oktaAuthConfig = {
  issuer: 'https://dev-9337965.okta.com/oauth2/default',
  clientId: '0oa2we0y8dmZG1mQ15d7',
  redirectUri: window.location.origin + '/login/callback',
};

const oktaSignInConfig = {
  baseUrl: 'https://dev-9337965.okta.com',
  clientId: '0oa2we0y8dmZG1mQ15d7',
  redirectUri: window.location.origin + '/login/callback',
  authParams: {
    
  }
};
export { oktaAuthConfig, oktaSignInConfig };