import React from 'react';
import './Side.css';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import Config from '../pages/Config/index';
import Home from '../pages/Home';
// import Profile from '../pages/Profile/Profile';
import UserList from '../pages/Users/index';
import List from '../pages/List/index';
import Post from '../pages/Post/index';
import Category from '../pages/Category/index';
import Logout from '../pages/Logout/index';

import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  VideoCameraOutlined,
  UploadOutlined,
  OrderedListOutlined,
  SettingOutlined,
} from '@ant-design/icons';

const { Header, Sider, Content } = Layout;

class Side extends React.Component {
  state = {
    collapsed: false,
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  render() {
    return (
      <Router>
        <Layout>
          <Sider trigger={null} collapsible collapsed={this.state.collapsed}>
            <div className="logo" />
            <Menu theme="dark" mode="inline" defaultSelectedKeys={['1']}>
              <Menu.Item key="1" icon={<UserOutlined />}>
                <Link to="/Home">ホーム</Link>
              </Menu.Item>
              <Menu.Item key="2" icon={<OrderedListOutlined />}>
                <Link to="/Post/0">新規投稿</Link>
              </Menu.Item>
              <Menu.Item key="3" icon={<OrderedListOutlined />}>
                <Link to="/List">投稿一覧</Link>
              </Menu.Item>
              <Menu.Item key="4" icon={<VideoCameraOutlined />}>
                <Link to="/Config">設定</Link>
              </Menu.Item>
              <Menu.Item key="5" icon={<SettingOutlined />}>
                <Link to="/Category">カテゴリ設定</Link>
              </Menu.Item>
              <Menu.Item key="6" icon={<UserOutlined />}>
                <Link to="/Users">ユーザー一覧</Link>
              </Menu.Item>
              {/* <Menu.Item key="6" icon={<UserOutlined />}>
                <Link to="/Profile">プロフィール</Link>
              </Menu.Item> */}
              <Menu.Item key="7" icon={<UploadOutlined />}>
                <Link to="/Logout">ログアウト</Link>
              </Menu.Item>
            </Menu>
          </Sider>
          <Layout className="site-layout">
            <Header className="site-layout-background" style={{ padding: 0 }}>
              {React.createElement(this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                className: 'trigger',
                onClick: this.toggle,
              })}
            </Header>
              <Content
                className="site-layout-background"
                style={{
                  margin: '24px 16px',
                  padding: 24,
                  minHeight: 280,
                }}
              >
                <Route path='/Home' component={Home}/>
                <Route path='/List' component={List}/>
                <Route path='/Config' component={Config}/>
                {/* <Route path='/Post/0' component={Post} /> */}
                <Route path='/Post/:id' component={Post} />
                <Route path='/Category' component={Category} />
                <Route path='/Users' component={UserList} />
                {/* <Route path="/Profile" component={Profile}/> */}
                <Route path="/Logout" component={Logout}/>
              </Content>
          </Layout>
        </Layout>
      </Router>
    );
  }
}

export default Side;
// ReactDOM.render(<Side />, mountNode);
