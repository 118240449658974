import React from 'react';
import QueueAnim from 'rc-queue-anim';
import { ApolloProvider } from '@apollo/client';
import client from '../../graphql/client';
import UserList from './UserList';

const Index = ():JSX.Element => {
  return (
    <>
      <QueueAnim>
        <div key="1">
          <ApolloProvider client={client}>
            <UserList />
          </ApolloProvider>
        </div>
      </QueueAnim>
    </>
  )
};
export default Index;