import React from 'react';
import QueueAnim from 'rc-queue-anim';
import CategoryList from './CategoryList';
import { ApolloProvider } from '@apollo/client';
import client from '../../graphql/client';

const Index = (): JSX.Element => {
  return (
    <>
      <QueueAnim>
        <div key="1">
          <h1>カテゴリ設定</h1>
          <ApolloProvider client={client}>
            <CategoryList/>
          </ApolloProvider>
        </div>
      </QueueAnim>
    </>
  );
};
export default Index;