import useSWR from 'swr';
import { request } from 'graphql-request';
import { Category} from '../models/category';

interface CategoryInterface {
  category: Category[];
  isLoading: boolean;
  error: string;
}

// useCategory カテゴリ一覧
export const useCategory: (props: boolean) => CategoryInterface = () => {
  const fetcher = (query: string) => request(`${process.env.REACT_APP_API}/graphql`, query); 
  const req =
  `{
    category {
      title
      categoryId
      recommendFlg
      createdAt
      updatedAt
    }
  }`;
  const { data, error } = useSWR(
    req,
    fetcher
  );
  // mutate(req, { ...data}, false)
  return {
    category: data ? data.category : [],
    isLoading: !error && !data,
    error: error,
  };
}
