import { gql } from '@apollo/client';

export const ADD_POST = gql`
mutation addPost(
  $authorId: Int!
  $categoryId: Int!
  $visible: Int!
  $title: String!
  $content: String!
  $articleUrl: String!
){
  createPosts(input: {authorId: $authorId, categoryId: $categoryId, title: $title, content: $content, visible: $visible, articleUrl: $articleUrl}) {
    authorId
    categoryId
    content
    title
    visible
    articleUrl
    createdAt
    updatedAt
  }
}
`;

export const EDIT_POST = gql`
mutation editPost(
  $documentId: Int!
  $authorId: Int!
  $categoryId: Int!
  $visible: Int!
  $title: String!
  $content: String!
  $articleUrl: String!
){
  editPosts(input: {documentId: $documentId, authorId: $authorId, categoryId: $categoryId, title: $title, content: $content, visible: $visible, articleUrl: $articleUrl}) {
    documentId
    authorId
    categoryId
    content
    title
    visible
    articleUrl
    createdAt
    updatedAt
  }
}
`;

export const ADD_CATEGORY = gql`
mutation addCategory(
  $title: String!
  $recommendFlg: Boolean!
)
{
  createCategory(input: {title: $title, recommendFlg: $recommendFlg}) {
    title
    recommendFlg
  }
}
`;

export const ADD_USER = gql`
mutation addUser(
  $name: String!
  $profile: String !
  $description: String!
  $address: String!
  $hobby: String!
  $gender: Int!
){
  createUser(input: {name: $name, profile: $profile, description: $description, address: $address, hobby: $hobby, gender: $gender}) {
    name
  }
}
`;

export const DEL_CATEGORY = gql`
mutation delCategory(
  $categoryId: Int!
){
  deleteCategory(input: {categoryId: $categoryId}) {
    categoryId
  }
}
`;