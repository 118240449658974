import React, { useState } from 'react';
import useSWR, { mutate } from 'swr';
import { request } from 'graphql-request';
import { Popconfirm, Table, Button, Spin} from 'antd';
import { useMutation } from '@apollo/client';

import CategoryAdd from './CategoryAdd';
import { DEL_CATEGORY } from '../../query/mutation';

const req =
`{
  category {
    title
    categoryId
    recommendFlg
    createdAt
    updatedAt
  }
}`;

const CategoryList:React.FC = ():JSX.Element => {
  const [value, setValue] = useState(false); // eslint-disable-line
  const [delCategory] = useMutation(DEL_CATEGORY); // eslint-disable-line
  const fetcher = (query: string) => request(`${process.env.REACT_APP_API}/graphql`, query); 
  const { data } = useSWR(req, fetcher);
  const category =  data ? data.category : [];

  function eventClick (values: any) {
    console.log(values);
  }
 
  function del(record: any) {
    delCategory({ variables: {categoryID: record.categoryId}});
  }

  async function handleClickCallback() {
    mutate(req, category, false)
    await mutate(req);
  };

  const columns = [
    {
      title: 'No',
      dataIndex: 'categoryId',
      key: 'categoryId',
    },
    {
      title: 'タイトル',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'トップ',
      dataIndex: 'recommendFlg',
      key: 'recommendFlg',
    },
    {
      title: '操作',
      dataIndex: 'delete',
      key: 'delete',
      render: (_: any , record: {categoryId: any}) =>
      (
        <Popconfirm title="削除しますか？" onConfirm={() => del(record)}>
          <Button type="primary">削除</Button>
        </Popconfirm>
      ),
    },
  ];
  return (
    <>
      <Spin spinning={false} tip="Loading...">
        <CategoryAdd setValue={handleClickCallback}/>
        {category &&
          <Table
            onRow={(record, rowIndex) => {
              return {
                onClick: event => {eventClick(record);}, // click row
              };
            }}
            columns={columns} dataSource={category}>
          </Table>
        }
      </Spin>
    </>
  );
};
export default CategoryList;