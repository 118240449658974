import React, { Component } from 'react';
import Side from './components/Side';
console.log(Component);
const Protected = () => {
  return (
    <>
      <div>
        <Side />
      </div>
    </>
  );
};
export default Protected;

// Del 2021.09.05 oktaの公式ドキュメントより引用
// import React from 'react';
// const Protected = () => <h3>Protected</h3>;
// export default Protected;