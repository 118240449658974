import React from 'react';
import { Spin, Row } from 'antd';
export const Loading = ():  JSX.Element => {
  return (
    <>
      <Row>
        <Spin size="large" spinning={true} tip="Loading..." />
      </Row>
    </>
  )
}