import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import ProForm, {
  ProFormSwitch,
  ProFormText,
} from '@ant-design/pro-form';

import SimpleMDE from 'react-simplemde-editor';
import 'easymde/dist/easymde.min.css';
import marked from 'marked';
import highlight from 'highlight.js';
import {Loading} from '../../components/loading';
import { useMutation } from '@apollo/client';
import { EDIT_POST } from '../../query/mutation';
import { CategoryListEdit } from '../../components/category';
import { GET_POST } from '../../query/posts';
import { Notification } from '../../components/notification';
import { Posts } from '../../models/posts';

marked.setOptions({
  highlight(code, lang) {
    return highlight.highlightAuto(code, [lang.split(':')[0]]).value;
  },
});

export type PostProps = {
  documentId?: number
};

const FormEdit = ({ documentId }: PostProps):JSX.Element => {
  
  const {data, loading, error } = useQuery(GET_POST, {variables: {documentId: documentId}}); // eslint-disable-line
  const [markdown, setMarkdown] = useState('');
  const [visible, setVisible] = useState(false); // eslint-disable-line
  const [confirmLoading, setConfirmLoading] = useState(false); // eslint-disable-line
  const [editPost] = useMutation(EDIT_POST, {refetchQueries : [{query: GET_POST, variables: {documentId: documentId}}]}); // eslint-disable-line
  const [value, setValue] = useState(0); // eslint-disable-line
  useEffect( () => {
    if (data) {
      setMarkdown(data.post.content);
      setValue(data.post.categoryId);
    }
  }, [data]);

  if (loading) {
    return (
      <>
      {Loading}
      </>
    );
  };

  const postData = async (values: Posts) => {
    const param = {
      data: values,
    };
    const visible = param.data.visible ? 1 : 0;
    editPost({ variables: {documentId: documentId, authorId: 1, categoryId: value, title: param.data.title, content: markdown, articleUrl: param.data.articleUrl, visible: visible}});
    setTimeout(() => {
      setVisible(false);
      setConfirmLoading(false);
      Notification({msg: "確認", description:"保存しました。"});
    }, 1000);
  }

  const onFinish = (values: Posts) => {
    // console.log(values);
    postData(values);
  };
  
  return (
    <>
      {data && 
      <ProForm
        onValuesChange={(changeValues) => console.log(changeValues)}
        onFinish={(values) => {
          onFinish(values as Posts);
          return Promise.resolve();
        }}
        initialValues={{
          title: data.post.title,
          articleUrl: data.post.articleUrl,
          content: data.post.content,
          visible: data.post.visible
        }}
      >
        <ProForm.Group>
          <ProFormText 
            width="xs"
            name="title"
            label="タイトル"
            placeholder="記事タイトルを入力してください。"
            rules={[{required: true, message: 'タイトルを入力してください。'}]}
          />
          <ProForm.Item
            label="カテゴリ"
            name="category"
          >
            <CategoryListEdit setValue={setValue} defaultValue={data.post.categoryId}/>
          </ProForm.Item>
          <ProFormText
            width="xs"
            label="記事URL"
            name="articleUrl"
            placeholder=""
            rules={[{required: true, message: '記事URLを入力してください。'}]}
          />
          <ProForm.Item>
            <ProFormSwitch fieldProps={{defaultChecked: data.post.visible}} label="公開" name="visible" valuePropName="checked" />
          </ProForm.Item>
        </ProForm.Group>
        <ProForm.Item name="content">
          <SimpleMDE value={data.post.content} onChange={(e) => setMarkdown(e)} />
          {/* <div id="body">
            <span dangerouslySetInnerHTML={{ __html: marked(markdown) }} />
          </div> */}
        </ProForm.Item>
      </ProForm>
      }
    </>
  );
};
export default FormEdit;
