import React from 'react';
import QueueAnim from 'rc-queue-anim';
import FormPost from './Form';
import FormEdit from './Edit';
import { ApolloProvider } from '@apollo/client';
import client from '../../graphql/client';

const Post:React.FC = (props: any):JSX.Element => {
  return (
    <>
      <QueueAnim>
        <div key="1">
          <ApolloProvider client={client}>
          {props.match.params.id > 0 ?
          <>
          <h2>記事編集</h2>
          <FormEdit documentId={props.match.params.id}/></>
          : 
          <>
          <h2>新規投稿</h2>
          <FormPost />
          </>
          }
          </ApolloProvider>
        </div>
      </QueueAnim>
    </>
  )
}
export default Post;