import useSWR from 'swr';
import { request } from 'graphql-request';
import { Posts } from '../models/posts';

interface PostsInterface {
  posts: Posts[];
  isLoading: boolean;
  error: any;
}

export const usePosts: () => PostsInterface = () => {
  const fetcher = (query: any) => request(`${process.env.REACT_APP_API}/graphql`, query);
  const { data, error } = useSWR(
    `{
      posts {
        documentId
        categoryId
        authorId
        content
        title
        articleUrl
        createdAt
        updatedAt
      }
    }`,
    fetcher
  );
  return {
    posts: data ? data.posts : [],
    isLoading: !error && !data,
    error: error,
  };
}
