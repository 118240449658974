import React, {useState,} from 'react';
import { Button } from 'antd';
import ProForm, {
  ProFormSwitch,
  ProFormText, 
} from '@ant-design/pro-form';
import SimpleMDE from 'react-simplemde-editor';
import 'easymde/dist/easymde.min.css';
import marked from 'marked';
import highlight from 'highlight.js';
import 'highlight.js/styles/github.css';
import { useMutation } from '@apollo/client';
import { ADD_POST } from '../../query/mutation';
import { CategoryList } from '../../components/category';
import { Notification } from '../../components/notification';
import { Posts } from '../../models/posts';

marked.setOptions({
  highlight: function (code, lang) {
    return highlight.highlightAuto(code, [lang.split(':')[0]]).value;
  }
});

const FormPost:React.FC = ():JSX.Element => {
  const [markdown, setMarkdown] = useState('');
  const [visible, setVisible] = useState(false); // eslint-disable-line
  const [confirmLoading, setConfirmLoading] = useState(false); // eslint-disable-line
  const [addPost, { loading, error }] = useMutation(ADD_POST); // eslint-disable-line
  const [value, setValue] = useState(0); // eslint-disable-line

  const postData = async (values: Posts) => {
    const param = {
      data: values,
    };
    const visible = param.data.visible ? 1 : 0;
    addPost({ variables: {authorId: 1, categoryId: value, title: param.data.title, content: markdown, articleUrl: param.data.articleUrl, visible: visible}});
    setTimeout(() => {
      setVisible(false);
      setConfirmLoading(false);
      Notification({msg: "確認", description:"保存しました。"});
    }, 1000);
  }

  const onFinish = (values: Posts) => {
    postData(values);
  };  

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const layout = {
    labelCol: {span:8},
    wrapperCol:{span:16},
  };

  return (
    <>
      <ProForm
        name="FormPost"
        onFinish={(values) => {
          onFinish(values as Posts);
          return Promise.resolve();
        }}
        onFinishFailed={onFinishFailed}
      >
        <ProForm.Group>
          <ProFormText
            width="xs"
            name="title"
            label="タイトル"
            placeholder="記事タイトルを入力してください。" 
          rules={[{required: true, message: 'タイトルを入力してください。'}]}
          />
          <ProForm.Item
            label="カテゴリ"
            name="category"
          >
            <CategoryList setValue={setValue} defaultValue={0}/>
          </ProForm.Item>
          <ProFormText
            width="xs"
            label="記事URL"
            name="articleUrl"
            placeholder=""
            rules={[{required: true, message: '記事URLを入力してください。'}]}
          />
          <ProForm.Item name="visible" label="公開">
            <ProFormSwitch/>
          </ProForm.Item>
        </ProForm.Group>
        <ProForm.Item name="content">
          <SimpleMDE onChange={(e) => setMarkdown(e)} />
          {/* <div id="body">
            <span dangerouslySetInnerHTML={{ __html: marked(markdown) }} />
          </div> */}
        </ProForm.Item>
        <ProForm.Item wrapperCol={{ ...layout.wrapperCol, offset:0}}>
          <Button type="primary" htmlType="submit">
            登録
          </Button>
        </ProForm.Item>
      </ProForm>
    </>
  )
}
export default FormPost;