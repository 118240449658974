import React from 'react';
import { useEffect} from "react";
import { useOktaAuth } from "@okta/okta-react";
import { useHistory } from 'react-router-dom';

const Index = ():JSX.Element => {
  const { oktaAuth, authState } = useOktaAuth();
  const history = useHistory();
  console.log(authState);
  useEffect( () => {
    oktaAuth.signOut();
    history.push('/login');
  });
  return(
    <>
      ログアウトしました。
    </>
  );
};
export default Index;