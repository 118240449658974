import { notification } from 'antd';

export type NotificationProps = {
  msg?: string,
  description?: string
};

export const Notification = ({
    msg,
    description
}: NotificationProps): void => {
  notification.open({
    message: msg,
    description: description,
  });
}