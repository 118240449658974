import React from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import { Button, Steps, Layout } from 'antd';
import { UserOutlined, SolutionOutlined, SmileOutlined } from '@ant-design/icons';
import { Loading } from './components/loading';

const { Step } = Steps;
const { Header, Content } = Layout;

const Home:React.FC = ():JSX.Element => {
  const history = useHistory();
  const { oktaAuth, authState } = useOktaAuth();

  if (!authState) return (
    <>
      <Loading />
    </>
  );

  const login = async () => history.push('/login');

  const logout = async () => oktaAuth.signOut();

  const button = authState.isAuthenticated ?
    <Button type="primary" onClick={logout}>ログアウト</Button> :
    <Button type="primary" onClick={login}>ログインする</Button>;

  return (
    <>
      <Layout className="layout">
        <Header>
          <div className="logo" />
        </Header>
        <Content className="site-layout" style={{ padding: '0 50px', marginTop: 64 }}>
          <Steps>
            <Step status="finish" title="ログイン" icon={<UserOutlined/>} />
            <Step status="finish" title="OKTA認証" icon={<SolutionOutlined/>} />
            <Step status="wait" title="ログイン完了" icon={<SmileOutlined />} />
          </Steps>
          <div>
            <Link to='/'>Home</Link><br/>
            <Link to='/protected'>Protected</Link><br/>
            {button}
          </div>
        </Content>
      </Layout>
    </>
  );
};
export default Home;