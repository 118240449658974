import React from 'react';
import { Select } from 'antd';
import { useCategory } from '../../hooks/use-category';

export const CategoryList = (props: any) => {
  const {category, isLoading, error} = useCategory(false); // eslint-disable-line
  function onChange(value: any) {
    props.setValue(value);
  }
  return (
    <>
      <Select style={{ width:140 }} onChange={onChange}>
        <option value={0}>未指定</option>
        {category && category.map((c) => {
          return (
            <><option value={c.categoryId}>{c.title}</option></>
          )
        }
        )}
      </Select>
    </>
  );
};
