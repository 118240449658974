import React, { useState, useEffect }  from 'react';
import { Select } from 'antd';
import { useCategory } from '../../hooks/use-category';

export const CategoryListEdit = (props: any):JSX.Element => {
  const {category, isLoading, error} = useCategory(false); // eslint-disable-line
  const [value, setDefault] = useState(0);
  const [loaded, setLoading] = useState(0);
  function onChange(value: any) {
    props.setValue(value);
  }
  useEffect( () => {
    setDefault(props.defaultValue);
    setLoading(1);
  }, [value]);

  return (
    <>
    {loaded &&
      <Select style={{ width:140 }} onChange={onChange} defaultValue={value}>
        <option value={0}>未指定</option>
        {category && category.map((c) => {
          return (
            <><option value={c.categoryId}>{c.title}</option></>
          )
        }
        )}
      </Select>
    }
    </>
  );
};
