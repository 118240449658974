import React from "react";
import { Table, Button } from "antd";
import { useHistory } from 'react-router-dom';
import { usePosts } from '../../hooks/use-posts';

const List: React.FC = ():JSX.Element => {
  const {posts, isLoading, error} = usePosts(); // eslint-disable-line
  const history = useHistory();
  console.log(posts);
  
  function onClick(e: any) {
    history.push(`/Post/${e.documentId}`);
  }

  const columns = [
    {
      title: 'No',
      dataIndex: 'documentId',
      key : 'documentId',
    },
    {
      title: 'タイトル',
      dataIndex: 'title',
      key : 'title',
    },
    {
      title: 'カテゴリ',
      dataIndex: 'categoryId',
      key: 'categoryId',
    },
    {
      title: '作成日',
      dataIndex: 'createdAt',
      key : 'createdAt',
    }, {
      title: '更新日',
      dataIndex: 'updatedAt',
      key : 'updatedAt',
    },{
      title: '編集',
      dataIndex: 'edit',
      key: 'edit',
      render: (_: any, record: {documentId: any}) => 
      (
        <Button type="primary" onClick={() => onClick(record)}>編集</Button>
      ),
    }
  ]
  return (
    <div key="1">
      <h2>
        投稿一覧
      </h2>
      <Table columns={columns} dataSource={posts} />
    </div>
  );
};
export default List;
