import React from 'react';
import QueueAnim from "rc-queue-anim";
import Config from './Config';
import { ApolloProvider } from '@apollo/client';
import client from '../../graphql/client';

const Index = ():JSX.Element => {
  return (
    <>
      <QueueAnim>
        <div key="1">
        <ApolloProvider client={client}>
          <Config/>
        </ApolloProvider>
        </div>
      </QueueAnim>
    </>
  )
};
export default Index;