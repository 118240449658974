import React from 'react';
import { Table } from 'antd';
import { useUser } from '../../hooks/use-user';

const UserList = ():JSX.Element => {
  const {user, isLoading, error} = useUser();
  const columns = [
    {
      title: 'id',
      dataIndex: 'id',
      key: 'id',
    },{
      title: 'name',
      dataIndex: 'name',
      key: 'name',
    },
  ]
  return (
    <>
      <h2>ユーザー一覧</h2>
      <Table columns={columns} dataSource={user} />
    </>
  )
};
export default UserList;