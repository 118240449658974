import React from 'react';
import SchemaForm from 'antd-schema-form';
import jsonSchema from '../../schema/config.json';

const Config = ():JSX.Element => {
  function onOk(form: any, value: any, keys: Array<string>) {
    console.log(value);
  }
  return (
    <>
      <h1>サイト設定</h1>
      <SchemaForm
        json={jsonSchema}
        onOk={onOk}
      />
    </>
  );
};
export default Config;