import { gql } from '@apollo/client';

export const GET_POST = gql`
  query post($documentId: ID!) {
    post(documentID: $documentId) {
      authorId
      documentId
      categoryId
      title
      content
      visible
      articleUrl
      createdAt
      updatedAt      
    }
  }
`;